<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Create a Display Template
      </h3>
      <div slot="content">
        <AddDisplayTemplate />
      </div>
    </prompt>

    <div class="row fadeInUp content-body" role="main">
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-3">
            <ConfigurationNav />
          </div>
          <div class="col-12 col-md-9">
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-header">
                <h5>
                  <a
                    href="https://automation.app/helpdesk/display-templates-for-orders-create-order-pdfs-or-html-documents/"
                    target="_blank"
                    style="margin-top: 5px; display: inline-block;"
                    >Display template</a
                  >
                  <button
                    class="btn btn-sm btn-primary float-right m-1"
                    @click="openModal"
                  >
                    Create display template
                  </button>
                  <Tooltip
                    class="ml-2"
                    :title="'Display templates are available from order pages, to enable print of any document - or as a specialised order view.'"
                  />
                </h5>
              </div>
              <div
                v-if="Object.keys(displays.data).length !== 0"
                class="card-body"
              >
                <data-table
                  :headers="headers()"
                  sort-url="/configuration/display"
                  :method="'get'"
                  :data="displays.data"
                >
                  <template #name="{ item }">
                    {{ item.data.name }}
                  </template>
                  <template #created_at="{ item }">
                    {{ item.created_at | formatLocaleDateString }}
                  </template>
                  <template #updated_at="{ item }">
                    {{ item.updated_at | formatLocaleDateString }}
                  </template>
                  <template #actions="{ item }">
                    <div
                      data-title="Here are the actions available to help you manage your views"
                      data-intro=""
                      data-step="3"
                      data-position="left"
                    >
                      <router-link
                        :to="{
                          name: 'organization.display.template',
                          params: { id: item.id },
                        }"
                        class="btn btn-sm btn-outline-secondary mr-3"
                      >
                        Edit
                      </router-link>
                      <DeleteButton
                        class="d-inline-block"
                        :uri="`configurationData/${item.id}`"
                        :name="item.data.name"
                        :isSmall="true"
                        @deleted="deleteContent(item.id)"
                      />
                    </div>
                  </template>
                </data-table>
                <pagination
                  :data="displays"
                  :limit="3"
                  :show-disabled="true"
                  align="right"
                  @pagination-change-page="getDisplays"
                >
                  <span slot="prev-nav" class="text-capitalize"
                    ><i class="fas fa-chevron-left mr-2" /> Prev</span
                  >
                  <span slot="next-nav" class="text-capitalize"
                    >Next<i class="fas fa-chevron-right ml-2"
                  /></span>
                </pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
// import BaseModal from "@/components/UI/BaseModal.vue";
import Prompt from "@/components/UI/Prompt.vue";
import DataTable from "@/components/DataTable/DataTable.vue";
import ConfigurationNav from "@/components/Nav/ConfigurationNav.vue";
import AddDisplayTemplate from "@/components/UI/AddDisplayTemplate.vue";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import Tooltip from "@/components/UI/Tooltip.vue";

export default {
  name: "DisplayTemplateIndex",
  components: {
    // BaseModal,
    Prompt,
    Default,
    AddDisplayTemplate,
    DataTable,
    ConfigurationNav,
    DeleteButton,
    Tooltip,
  },
  beforeRouteEnter(to, from, next) {
    let uri = "/configuration/display?page=1";

    if (to.query.sort && to.query.dir) {
      uri += "&sort=" + to.query.sort + "&dir=" + to.query.dir;
    }

    jsonAPI.get(uri).then((response) => {
      next((vm) => {
        vm.displays = response.data;
      });
    });
  },
  data: function() {
    return {
      layout: "Default",
      loading: false,
      page: 1,
      displays: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
      tempId: "",
      isModalVisible: false,
    };
  },
  computed: {
    ...mapGetters(["modalActive"]),
  },
  methods: {
    headers() {
      return [
        { title: "Name", key: "name", sortable: true, sortKey: "name" },
        {
          title: "Created on",
          key: "created_at",
          sortable: true,
          sortKey: "created_at",
        },
        {
          title: "Last updated",
          key: "updated_at",
          sortable: true,
          sortKey: "updated_at",
        },
        { title: "Actions", key: "actions" },
      ];
    },
    openModal() {
      this.isModalVisible = true;
    },
    getDisplays(page) {
      let uri = "/configuration/display?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.displays = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
    deleteContent(id) {
      this.displays.data = this.displays.data.filter((obj) => {
        return obj.id !== id;
      });
    },
  },
};
</script>

<style scoped></style>
