<template>
  <div class="modal-body add-tag">
    <form @submit.prevent="addDisplayTemplate">
      <div class="row">
        <div class="col-md-12">
          <p>
            Give your new display template a name. Template names are never
            public, and are visible only to you.
          </p>
          <div class="form-group">
            <input
              v-model="form.data.name"
              type="text"
              class="form-control"
              placeholder="Display template name"
              required
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <ButtonLoading
              :loading="loading"
              :title="'Create'"
              :class="'btn btn-primary float-right'"
            />
            <button
              type="button"
              class="btn btn-primary float-right mr-3"
              @click="close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { postToApi } from "@/helpers/apiConnection";
import ButtonLoading from "../Form/ButtonLoading.vue";

export default {
  name: "AddDisplayTemplate",
  components: {
    ButtonLoading,
  },
  data() {
    return {
      loading: false,
      form: {
        data: { name: "" },
        group: "display",
      },
    };
  },
  computed: {
    ...mapActions(["closeModal", "resetModalData"]),
  },
  methods: {
    async addDisplayTemplate() {
      this.loading = true;
      const res = await postToApi(
        "/configurationData",
        this.form,
        "Display template created"
      ).catch(() => {
        this.loading = false;
      });
      if (res) {
        setTimeout(() => {
          this.$parent.$emit("close");
          this.$router.push({
            name: "organization.display.template",
            params: { id: res.id },
          });
        }, 1000);
      }
      this.loading = false;
    },
    close() {
      this.$parent.$emit("close");
    },
  },
};
</script>

<style scoped>
.form-group input {
  margin-bottom: 40px;
}
.add-tags .badge:hover {
  cursor: pointer;
}
.tags span {
  display: inline-block;
  margin-bottom: 5px;
}
</style>
