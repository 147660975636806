var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"component"},[_c('prompt',{attrs:{"active":_vm.isModalVisible},on:{"close":function($event){_vm.isModalVisible = false}}},[_c('h3',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" Create a Display Template ")]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('AddDisplayTemplate')],1)]),_c('div',{staticClass:"row fadeInUp content-body",attrs:{"role":"main"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('ConfigurationNav')],1),_c('div',{staticClass:"col-12 col-md-9"},[_c('div',{staticClass:"card border-0 shadow-sm mb-4"},[_c('div',{staticClass:"card-header"},[_c('h5',[_c('a',{staticStyle:{"margin-top":"5px","display":"inline-block"},attrs:{"href":"https://automation.app/helpdesk/display-templates-for-orders-create-order-pdfs-or-html-documents/","target":"_blank"}},[_vm._v("Display template")]),_c('button',{staticClass:"btn btn-sm btn-primary float-right m-1",on:{"click":_vm.openModal}},[_vm._v(" Create display template ")]),_c('Tooltip',{staticClass:"ml-2",attrs:{"title":'Display templates are available from order pages, to enable print of any document - or as a specialised order view.'}})],1)]),(Object.keys(_vm.displays.data).length !== 0)?_c('div',{staticClass:"card-body"},[_c('data-table',{attrs:{"headers":_vm.headers(),"sort-url":"/configuration/display","method":'get',"data":_vm.displays.data},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.data.name)+" ")]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatLocaleDateString")(item.updated_at))+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"data-title":"Here are the actions available to help you manage your views","data-intro":"","data-step":"3","data-position":"left"}},[_c('router-link',{staticClass:"btn btn-sm btn-outline-secondary mr-3",attrs:{"to":{
                        name: 'organization.display.template',
                        params: { id: item.id },
                      }}},[_vm._v(" Edit ")]),_c('DeleteButton',{staticClass:"d-inline-block",attrs:{"uri":("configurationData/" + (item.id)),"name":item.data.name,"isSmall":true},on:{"deleted":function($event){return _vm.deleteContent(item.id)}}})],1)]}}],null,false,1174851140)}),_c('pagination',{attrs:{"data":_vm.displays,"limit":3,"show-disabled":true,"align":"right"},on:{"pagination-change-page":_vm.getDisplays}},[_c('span',{staticClass:"text-capitalize",attrs:{"slot":"prev-nav"},slot:"prev-nav"},[_c('i',{staticClass:"fas fa-chevron-left mr-2"}),_vm._v(" Prev")]),_c('span',{staticClass:"text-capitalize",attrs:{"slot":"next-nav"},slot:"next-nav"},[_vm._v("Next"),_c('i',{staticClass:"fas fa-chevron-right ml-2"})])])],1):_vm._e()])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }